











































import QuestionElementPreview from "@/components/CardPreview/v2CardElements/QuestionElementPreview.vue";
import InputElementPreview from "@/components/CardPreview/v2CardElements/InputElementPreview.vue";
import MarkdownElementPreview from "@/components/CardPreview/v2CardElements/MarkdownElementPreview.vue";
import CheckboxElementPreview from "@/components/CardPreview/v2CardElements/CheckboxElementPreview.vue";
import ReferenceElementPreview from "@/components/CardPreview/v2CardElements/ReferenceElementPreview.vue";
import SumElementPreview from "@/components/CardPreview/v2CardElements/SumElementPreview.vue";
import Vue from "vue";

export default Vue.extend({
  components: {
    QuestionElementPreview,
    InputElementPreview,
    MarkdownElementPreview,
    CheckboxElementPreview,
    ReferenceElementPreview,
    SumElementPreview,
  },
  props: ["card", "disableContentHighlight"],
  data: () => ({}),
  computed: {},
});
