





import Vue from "vue";
import TheCardPreview from "./TheCardPreview.vue";
import TheCardPreviewV2 from "@/components/CardPreview/TheCardPreviewV2.vue";
export default Vue.extend({
  components: { TheCardPreview, TheCardPreviewV2 },
  props: ["card", "disableContentHighlight"],
  data: () => ({}),
  computed: {
    isV2(): boolean {
      if (this.card) {
        return "elements" in this.card;
      } else return false;
    },
  },
});
