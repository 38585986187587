























import Vue from "vue";
import VueMarkdown from "vue-markdown";
export default Vue.extend({
  components: { VueMarkdown },
  props: ["previewElement"],
});
