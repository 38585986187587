












































































































import Vue from "vue";
import { Editor } from "vuetify-markdown-editor";
import { CardPayload } from "@/models/Card";

export default Vue.extend({
  components: {
    Editor,
  },
  props: ["card"],

  data: () => ({
    renderConfig: {},
  }),
  computed: {
    cardPayload(): CardPayload {
      return this.card.payload.payload.find(() => true);
    },
  },
  methods: {
    isLastIndex(index, list) {
      return index == list.length - 1;
    },
  },
});
