

















































import { CardPayload } from "@/models/Card";
import Vue from "vue";
import { Editor } from "vuetify-markdown-editor";

export default Vue.extend({
  components: {
    Editor,
  },
  props: ["card"],
  data: () => ({
    renderConfig: {},
  }),
  computed: {
    cardPayload(): CardPayload {
      return this.card.payload;
    },

    cardPayloadExists(): boolean {
      return (
        Boolean(this.card.payload.title) ||
        Boolean(this.card.payload.subtitle) ||
        Boolean(this.card.payload.description) ||
        Boolean(this.card.payload.image)
      );
    },
  },
});
