<template>
  <v-container>
    <!-- percentage -->
    <div v-if="cardPayload.type === 'percentage'">
      <v-row justify="center">
        <v-card
          v-for="(value, index) in cardPayload.options"
          :key="index"
          min-height="1.25em"
          min-width="18.75em"
          max-width="18.75em"
          class="sys-ma-1"
        >
          <v-container>
            <v-row no-gutters>
              <v-col class="bold" cols="12" sm="6" md="8">{{
                value.title
              }}</v-col>
              <v-col cols="6" md="4" class="sys-pa-3 berry bold">--% </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-row>
      <v-row v-if="customOptionsEnabled" class="sys-ma-7 customOption">
        + Bereich hinzufügen
      </v-row>
      <v-row v-if="forceDistribution" class="berry bold sys-pa-5" justify="end"
        >0 von 100% vergeben
      </v-row>
    </div>
    <!-- checkbox -->
    <div v-else-if="cardPayload.type === 'checkbox'">
      <v-row justify="center">
        <v-card
          v-for="(value, index) in cardPayload.options"
          :key="index"
          min-height="1.25em"
          min-width="18.75em"
          max-width="18.75em"
          class="sys-ma-1"
        >
          <v-container>
            <v-row no-gutters>
              <v-col class="bold" cols="12" sm="6" md="8">{{
                value.title
              }}</v-col>
              <v-col cols="6" md="4"
                ><v-checkbox v-model="ex4" color="accent" hide-details>
                </v-checkbox
              ></v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-row>
      <v-row v-if="customOptionsEnabled" class="sys-ma-7 customOption">
        + Bereich hinzufügen
      </v-row>
    </div>
    <!-- points -->
    <div v-else-if="cardPayload.type === 'points'" class="sys-pa-5">
      <v-row justify="center">
        <v-card
          v-for="(value, index) in cardPayload.options"
          :key="index"
          min-height="1.25em"
          min-width="18.75em"
          max-width="18.75em"
          class="ma-1"
        >
          <v-container>
            <v-row no-gutters>
              <v-col class="bold" cols="12" sm="6" md="8">{{
                value.title
              }}</v-col>
              <v-col cols="6" md="4" class="sys-pa-3 berry bold">0 Pkt </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-row>
      <v-row v-if="customOptionsEnabled" class="sys-ma-7 customOption">
        + Bereich hinzufügen
      </v-row>
      <v-row v-if="forceDistribution" class="berry bold sys-pa-5" justify="end"
        >0 von 10 Punkten vergeben
      </v-row>
    </div>
    <!-- percentage_comparison -->
    <div
      v-else-if="cardPayload.type === 'percentage_comparison'"
      class="sys-pa-5"
    >
      <v-row>Only analysis -> no display</v-row>
    </div>
  </v-container>
</template>
<script>
import Vue from "vue";

export default Vue.extend({
  props: ["card"],
  data: () => ({
    ex4: null,
  }),
  computed: {
    cardPayload() {
      return this.card.payload;
    },
    forceDistribution() {
      return JSON.parse(this.card.payload.forceDistribution);
    },
    customOptionsEnabled() {
      return JSON.parse(this.card.payload.customOptionsEnabled);
    },
  },
});
</script>
<style lang="scss" scoped>
@import "@/assets/styles/CardPreview.scss";

.customOption {
  color: #969b9b;
}
.center {
  justify-content: center;
}
</style>
