

































































































import Vue from "vue";
import ContentCardPreview from "@/components/CardPreview/v1CardTypes/ContentCardPreview.vue";
import FinishedCardPreview from "@/components/CardPreview/v1CardTypes/FinishedCardPreview.vue";
import FreeInputCardPreview from "@/components/CardPreview/v1CardTypes/FreeInputCardPreview.vue";
import QuestionCardPreview from "@/components/CardPreview/v1CardTypes/QuestionCardPreview.vue";
import MultipleChoiceWAnswerCardPreview from "@/components/CardPreview/v1CardTypes/MultipleChoiceWAnswerCardPreview.vue";
import PdfCardPreview from "@/components/CardPreview/v1CardTypes/PdfCardPreview.vue";
import DragDropCardPreview from "@/components/CardPreview/v1CardTypes/DragDropCardPreview.vue";
import SumCardPreview from "@/components/CardPreview/v1CardTypes/SumCardPreview.vue";
import PtCardPreview from "@/components/CardPreview/v1CardTypes/PtCardPreview.vue";
import PtTotalCardPreview from "@/components/CardPreview/v1CardTypes/PtTotalPreview.vue";
import { Editor } from "vuetify-markdown-editor";
export default Vue.extend({
  components: {
    ContentCardPreview,
    FinishedCardPreview,
    FreeInputCardPreview,
    QuestionCardPreview,
    MultipleChoiceWAnswerCardPreview,
    PdfCardPreview,
    DragDropCardPreview,
    SumCardPreview,
    PtCardPreview,
    PtTotalCardPreview,
    Editor,
  },
  props: ["card", "disableContentHighlight"],
  data: () => ({
    renderConfig: {},
  }),
  computed: {},
});
