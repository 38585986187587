



























import Vue from "vue";
import { CardPayload } from "@/models/Card";

export default Vue.extend({
  props: ["card"],
  computed: {
    cardPayload(): CardPayload {
      return this.card.payload.payload.find(() => true);
    },
  },
});
