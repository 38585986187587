













import Vue from "vue";
import { CardPayload } from "@/models/Card";

export default Vue.extend({
  props: ["card"],
  computed: {
    cardPayload(): CardPayload {
      return this.card.payload;
    },
    evaluationTitles(): [string?] {
      let titles: [string?] = [];
      this.cardPayload.evaluationMap.forEach((element) =>
        titles.push(element.title)
      );
      return titles;
    },
  },
});
