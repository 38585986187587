






















import { CardPayload } from "@/models/Card";
import Vue from "vue";

// interface Computed {
//   cardPayload?: CardPayload;
// }

export default Vue.extend({
  props: ["card"],
  computed: {
    cardPayload(): CardPayload {
      return this.card.payload;
    },
  },
});
